<template>
  <v-card outlined style="height: 100%" class="graphic-one">
    <card-toolbar
      :graphic="graphic"
      :title="'Evaluaciones por empresas y estatus'"
      :helpId="22"
      @showFilters="filtersDialog = true"
      @deleteGraphic="$emit('deleteGraphic', $event)"
    />

    <apexchart
      :key="index"
      style="min-height: 200px !important"
      :height="'90%'"
      :type="graphic.type_chart"
      :options="chartOptions"
      :series="chartSeries"
    />
    <span style="font-size: 10px; position: absolute; right: 5px; bottom: 5px"
      >{{
        new Date(startAt).toLocaleDateString('es-VE', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'UTC',
        })
      }}
      -
      {{
        new Date(endAt).toLocaleDateString('es-VE', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'UTC',
        })
      }}</span
    >
    <v-dialog
      v-model="filtersDialog"
      width="auto"
      :max-width="$vuetify.breakpoint.xs ? '90%' : '50%'"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Filtrar: <small class="ml-2">{{ ' ' + graphic.title }}</small>
        </v-card-title>

        <date-filter
          :startAt.sync="startAt"
          :endAt.sync="endAt"
          :periodicity="periodicity"
          :validDate.sync="validDate"
        />

        <v-divider />

        <v-row class="mx-0 graphic-one">
          <v-col cols="12">
            <label>Empresas: </label>
            <multiselect
              v-model="selectedCompanies"
              label="name"
              track-by="id"
              placeholder="Buscar"
              open-direction="bottom"
              :options="allowedCompanies"
              :multiple="true"
              :searchable="true"
              :loading="isLoading"
              :internal-search="true"
              :clear-on-select="true"
              :close-on-select="true"
              :options-limit="300"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
            >
              <span slot="noResult"
                >No se encontraron elementos. Considere cambiar la consulta de
                búsqueda.</span
              >
            </multiselect>
          </v-col>
        </v-row>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            :disabled="!validFilter"
            @click.prevent="getChartData(), (filtersDialog = false)"
          >
            Aplicar filtros
          </v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  import DateFilter from '@/components/Dashboards/Filters/DateFilter'
  import { decryptData } from '@/utils/encryption'
  import { itemsPeriodicity, toLocaleDateString } from '@/utils/helpers'
  import EvaluationStatus from '@/data/SupervisionControl/evaluationStatus'
  import CardToolbar from '@/components/Dashboards/CardToolbar'
  import { getIntervalDatesByPeriodicity } from '@/utils/dateFunctions'
  import apexChartOptions from '@/utils/apexChartOptions'

  const today = new Date()
  export default {
    name: 'Graphic',
    components: { CardToolbar, DateFilter },
    props: {
      graphic: {
        type: Object,
        required: true,
        default: () => {},
      },
      generalStartAt: {
        type: String,
        required: true,
      },
      generalEndAt: {
        type: String,
        required: true,
      },
      generalPeriodicity: {
        type: Number,
        required: true,
      },
      allowedCompanies: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        index: 0,
        isLoading: false,
        validDate: true,
        filtersDialog: false,
        selectedCompanies: [],
        chartOptions: JSON.parse(JSON.stringify(apexChartOptions)),
        chartSeries: [],
        itemsPeriodicity: itemsPeriodicity,
        periodicity: 3,
        startAt: new Date(today.getFullYear(), today.getMonth() - 1, 1)
          .toISOString()
          .split('T')[0],
        endAt: new Date(today.getFullYear(), today.getMonth(), 0)
          .toISOString()
          .split('T')[0],
      }
    },
    filters: {
      toStatusText(status) {
        if (!status) return ''
        switch (status) {
          case 0:
            return 'Asignadas'
          case 1:
            return 'En curso'
          case 2:
            return 'Completadas'
          case 3:
            return 'Finalizadas'
          default:
            return ''
        }
      },
    },
    created() {
      this.periodicity = this.generalPeriodicity
      this.startAt = this.generalStartAt
      this.endAt = this.generalEndAt
      this.setDefaultOptions()
      this.getChartData()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      dateString() {
        return `&startAt=${this.startAt}&endAt=${this.endAt}`
      },
      validFilter() {
        return this.validDate
      },
      companyString() {
        if (this.selectedCompanies.length > 0) {
          let string = ''
          this.selectedCompanies.forEach((company, index) => {
            string = string + `&companies[${index}]=${company.id}`
          })
          return string
        } else {
          return `&company_id=${this.company.id}`
        }
      },
      computedChartOptions() {
        return this.chartOptions
      },
    },
    watch: {
      periodicity(newValue) {
        const interval = this.getIntervalDatesByPeriodicity(today, newValue)
        this.startAt = interval.startAt
        this.endAt = interval.endAt
        this.getChartData()
      },
      generalPeriodicity(newValue) {
        this.periodicity = newValue
      },
      generalStartAt(newValue) {
        this.startAt = newValue
      },
      generalEndAt(newValue) {
        this.endAt = newValue
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      getIntervalDatesByPeriodicity: getIntervalDatesByPeriodicity,
      setDefaultOptions() {
        this.chartOptions.xaxis.title.text = this.graphic.x_axis
        this.chartOptions.yaxis.title.text = this.graphic.y_axis
        this.chartOptions.chart.stacked = this.graphic.stacked
        this.chartOptions.plotOptions.bar.horizontal = true
      },
      getChartData() {
        this.$axios
          .get(this.graphic.endpoint + this.companyString + this.dateString)
          .then((response) => {
            response.data.series.forEach((element) => {
              const status = EvaluationStatus.find(
                (status) =>
                  status.id === parseInt(element.name) && !status.passed && !status.failed
              )
              element.name = status.name
              element.color = status.color
            })
            this.chartSeries = response.data.series

            this.chartOptions.xaxis.categories = response.data.labels
            this.index++
          })
      },
    },
  }
</script>
<style lang="scss">
  .graphic-one {
    .multiselect {
      height: auto;
      min-height: 25px;
    }
    .multiselect__select {
      height: 25px;
      padding: 0px;
    }
    .multiselect__placeholder {
      margin-bottom: 0px;
      padding-top: 2px;
    }
    .multiselect__tags {
      overflow-y: auto;
      height: auto;
      min-height: 25px;
      display: flex;
      padding: 0px 5px;
      max-height: 150px;
      border: 1px solid #c1c1c1;
      -ms-flex-line-pack: center;
      align-content: center;
    }
    .multiselect__tags-wrap {
      display: -webkit-box;
    }

    .multiselect__option {
      display: flex;
      height: 25px;
      padding: 5px;
      min-height: 25px;
      align-items: center;
    }
    .multiselect__tag {
      margin: 1px;
      display: inline-flex;
    }
    .multiselect__tags {
      padding: 1px 3px;
    }

    .multiselect__single {
      padding: 2px;
      margin: 0px;
      height: 23px;
    }
    .multiselect__option:after {
      line-height: 25px;
    }
    .multiselect,
    .multiselect__input,
    .multiselect__single {
      font-size: 14px;
    }
    .multiselect__content {
      padding: 0px;
    }
    .multiselect__content-wrapper {
      height: 100px;
      position: relative;
    }
  }
</style>
